

import req from '../../libs/axios'

export function getAviaCompanies(params) {
  return req({
    url: '/api/avia-companies',
    method: 'get',
    params
  })
}

export function showAviaCompanies(id) {
  return req({
    url: `/api/avia-companies/${id}`,
    method: 'get',
  })
}

export function addAviaCompanies(data) {
  return req({
    url: '/api/avia-companies',
    method: 'post',
    data
  })
}

export function updateAviaCompanies(data) {
  return req({
    url: `/api/avia-companies/${data.id}`,
    method: 'put',
    data
  })
}

export function deleteAviaCompanies(id) {
  return req({
    url: `/api/avia-companies/${id}`,
    method: 'delete',
  })
}

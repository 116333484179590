<template>
  <div>
  <b-overlay :show="loading"> 
    <validation-observer ref="refFormObserver">
      <validation-provider #default="validationContext" :name="$t('message.Name')" rules="required">
        <b-form-group :label="$t('message.Name')" for="name">
          <b-form-input autofocus v-model="form.name"></b-form-input>
          <span class="text-danger">{{ validationContext.errors[0] }}</span>
        </b-form-group>
      </validation-provider>

      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="handleClose">
          {{$t('message.Cancel')}}
        </b-button>
      </div>

    </validation-observer>
    </b-overlay> 
  </div>
</template>

<script>
  import {ValidationProvider, ValidationObserver} from 'vee-validate'
  import { required} from '@validations';
  import {showAviaCompanies, updateAviaCompanies, addAviaCompanies} from '@/api/avia-companies/index'

  export default {
    name: 'BranchForm',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      id: {
        default: null
      }
    },
    data() {
      return {
        required,
        form: {
          name: '',
        },
        disabled: false,
        loading: false
      }
    },
    methods: {
      handleSubmit() {
        const isValid = this.$refs.refFormObserver.validate();
        this.disabled = true;
        if(isValid) {
          this.loading = true
          let data = {...this.form}
          if(this.id === null) {
            addAviaCompanies(data).then( () => {
              this.handleClose();
            }).finally( () => {
              this.loading = false;
              this.disabled = false;
            })
          } else {
            data.id = this.id
            updateAviaCompanies(data).then( () => {
              this.handleClose();
            }).finally( () => {
              this.loading = false;
              this.disabled = false;
            })
          }
        }
      },
      handleClose() {
        this.$emit('handleClose')
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            showAviaCompanies(val).then(res => {
              this.form = res.data.data
            })
          }
        }
      }
    }
  }
</script>
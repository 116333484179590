<template>
  <div class="conteiner">
    <div class="w-100 d-flex justify-content-end">
      <b-button variant="primary" @click="handleAddItem">
        {{$t('message.Add')}}
      </b-button>
    </div>

    <b-card class="my-2">
      <b-table
        :fields="fields"
        :items="items"
        responsive
        :busy="busy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <b-button
                  variant="outline-warning"
                  size="sm"
                  @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal
      v-model="modal"
      no-close-on-backdrop
      hide-footer
    >
      <Modal 
        :id="id"
        @handleClose="handleClose"
      />
    </b-modal>
  </div>
</template>

<script>
  import Modal from './components/Modal.vue';
  import {getAviaCompanies, deleteAviaCompanies} from '@/api/avia-companies/index'
import Swal from 'sweetalert2'
  export default {
    name: "Avia-Company",
    components: {
      Modal
    },
    data() {
      return {
        modal: false,
        id: null,
        items: [],
        fields: [
          {key: 'id', label: this.$t('message.ID')},
          {key: 'name', label: this.$t('message.name_title')},
          {key: 'action', label: this.$t('message.Action')},
        ],
        busy: true,
      }
    },
    created() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        getAviaCompanies().then( res => {
          this.items = res.data.data;
          this.busy = false;
        })
      },
      handleAddItem() {
        this.modal = true;
        this.id = null;
      },
      handleEdit(id) {
        this.modal = true;
        this.id = id;
      },
      handleClose() {
        this.modal = false;
        this.id = null;
        this.fetchData();
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteAviaCompanies(id).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchData()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
    }
  }
</script>